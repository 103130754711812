<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    form-icon="PlusIcon"
    :form-title="formTitle"
    :loading="resourceId && loading"
    :saving="saving"
    save-icon="SaveIcon"
    save-label="Save"
    @close="close"
    @submit="save"
  >
    <form
      id="formSidebar"
      autocomplete="off"
      class="m-2 tw-pt-2"
      style="flex: 1"
      @submit.prevent
    >
      <prozess-input
        v-model="resource.metaStatusName"
        :error="$hasError('metaStatusName')"
        :icon="MetastatusTypeIcons[resourceName]"
        :placeholder="$t($case.title(resourceName)) + ' ' + $t('Name')"
        field="metaStatusName"
        name="metaStatusName"
        @enter="save"
      />
      <ProzessIconSelector
        v-if="resourceName === MetastatusTypes.CATEGORY"
        v-model="resource.icon"
        :error="$hasError('icon')"
        required
        :clear-search-on-select="false"
        @enter="save"
      />
      <prozess-input
        v-if="resourceName === MetastatusTypes.PRIORITY"
        v-model="resource.metaStatusRank"
        :error="$hasError('metaStatusRank')"
        icon="RepeatIcon"
        :placeholder="$t('Rank')"
        field="metaStatusRank"
        name="metaStatusRank"
        type="number"
        step="1"
        @keypress="onlyAllowNumber"
        @enter="save"
      />
      <prozess-field-wrapper :error="$hasError('entities')" class="mb-1">
        <div class="form-sidebar__options w-100">
          <div
            class="p-1 form-sidebar__options-header d-flex justify-content-between"
          >
            <h5>{{ $t('Add to Entities') }}</h5>
            <h5>{{ $t('Set as default') }}</h5>
          </div>
          <div style="overflow-y: auto; max-height: 200px;">
            <div
              v-for="(entity, i) of entities"
              :key="`metastatus-entity-${i}`"
              class="d-flex align-items-center justify-content-between p-1"
              style="height: 45px;"
            >
              <div>
                <b-form-checkbox
                  v-model="entity.isIncluded"
                  inline
                  @keyup.enter.native="save"
                />
              </div>
              <div class="d-flex w-100">
                <span>{{ $t(entity.name) }}</span>
              </div>
              <div
                class="prozess-metastatus__entity-default d-flex align-items-center"
              >
                <b-form-checkbox
                  v-model="entity.isDefaultValue"
                  inline
                  switch
                  :disabled="!entity.isIncluded"
                  @keyup.enter.native="save"
                />
              </div>
            </div>
          </div>
        </div>
      </prozess-field-wrapper>
      <div v-if="resourceName==='category'" class="form-sidebar__options w-100">
        <div
          class="p-1 form-sidebar__options-header d-flex justify-content-between"
        >
          <h5>{{ $t('Standard Category') }}</h5>
        </div>
        <b-form-group class="mb-1">
          <b-form-radio-group
            v-model="selected"
            class="p-1 mb-3"
            :options="standardEntities"
            name="radios-stacked"
            style="height: 45px;"
            stacked
          />
        </b-form-group>
      </div>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import MetastatusTypes, {
  MetastatusTypeIcons,
} from '@/constants/metastatusTypes'
import { metastatusSchema } from '@/schema/metastatus'
import ProzessIconSelector from '@/components/shared/ProzessIconSelector.vue'

// TODO: This should be populated dynamically
const defaultEntities = [
  {
    name: 'Inquiries',
    schemaName: 'core',
    tableName: 'inquiry',
    isIncluded: false,
    isDefaultValue: false,
  },
  {
    name: 'To Do',
    schemaName: 'core',
    tableName: 'todo',
    isIncluded: false,
    isDefaultValue: false,
  },
  {
    name: 'Activities',
    schemaName: 'crm',
    tableName: 'activities',
    isIncluded: false,
    isDefaultValue: false,
  },
  {
    name: 'Company',
    schemaName: 'crm',
    tableName: 'company',
    isIncluded: false,
    isDefaultValue: false,
  },
  {
    name: 'Contact',
    schemaName: 'crm',
    tableName: 'contact',
    isIncluded: false,
    isDefaultValue: false,
  },
  {
    name: 'Dashboard',
    schemaName: 'dashboard',
    tableName: 'dashboard',
    isIncluded: false,
    isDefaultValue: false,
  },
  {
    name: 'Commision Models',
    schemaName: 'crm',
    tableName: 'model',
    isIncluded: false,
    isDefaultValue: false,
  },
]

const defaultStandardEntities = [
  {
    text: 'None',
    value: 'false',
    isCompanyContact: false,
  },
  {
    text: 'Company Contact',
    value: 'true',
    isCompanyContact: true,
  },
]

export default {
  components: {
    ProzessIconSelector,
  },

  props: {
    service: {
      type: Object,
      required: true,
    },
    resourceName: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      MetastatusTypes,
      MetastatusTypeIcons,
      resource: {},
      users: [],
      selectedUser: null,
      selectedUsers: [],
      saving: false,
      loading: false,
      errors: [],
      searchText: '',
      error: null,
      entities: defaultEntities,
      standardEntities: defaultStandardEntities,
      selected: 'false',
    }
  },
  computed: {
    formTitle() {
      return this.resourceId
        ? `${this.$t('Edit')} ${this.$t(this.$case.title(this.resourceName))}`
        : `${this.$t('Add New')} ${this.$t(
          this.$case.title(this.resourceName),
        )}`
    },
  },
  watch: {
    entities: {
      handler(entities) {
        entities.forEach((entity, index) => {
          if (!entity.isIncluded && entity.isDefaultValue) {
            this.$set(this.entities, index, {
              ...entity,
              isDefaultValue: false,
            })
          }
        })
      },
      deep: true,
    },
    visible(value) {
      if (value) {
        this.reset()
        if (this.resourceId) this.getOne()
      }
    },
  },
  methods: {
    onlyAllowNumber(e) {
      if (e.keyCode === 13) return true // allow enter to submit form
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(!e.keyCode ? e.which : e.keyCode)
      if (!regex.test(key)) {
        e.preventDefault()
        return false
      }
    },
    onBlurSearchUser() {
      this.users = []
    },
    removeUser(selectedUser) {
      this.selectedUsers = this.selectedUsers.filter(
        user => user.userUuid !== selectedUser.userUuid,
      )
    },
    selectUser(selectedUser) {
      this.users = []
      if (!selectedUser) return
      if (
        this.selectedUsers.some(
          user => user.userUuid === selectedUser.userUuid,
        ) === false
      ) {
        this.selectedUsers.push(selectedUser)
      }
      this.selectedUser = null
    },
    reset() {
      this.resource = {}
      this.errors = []
      this.selected = 'false'
      this.entities = this.$lodash.cloneDeep(defaultEntities)
    },
    async getOne() {
      this.loading = true
      const { response } = await this.$async(
        this.service.getOneResource(this.resourceId),
      )
      this.resource = response?.data

      this.selected = this.resource?.isCompanyContact

      this.entities = this.entities.map(item => {
        const entity = response?.data?.entities.find(
          value => value.schemaName === item.schemaName
            && value.tableName === item.tableName,
        )

        if (entity) {
          item.isIncluded = true
          item.isDefaultValue = entity.isDefaultValue
        }

        return item
      })
      this.loading = false
    },
    close() {
      this.$emit('close')
    },
    async save() {
      this.errors = []
      this.resource.metaStatusName = this.resource.metaStatusName
        ? this.resource.metaStatusName?.trim()
        : null

      if (this.resourceName === MetastatusTypes.PRIORITY) {
        this.resource.metaStatusRank = this.resource.metaStatusRank === ''
          ? null
          : parseInt(this.resource.metaStatusRank)
      }

      if (this.resourceId) {
        this.resource.entities = this.entities.filter(
          item => item.isIncluded,
        )

        this.resource.removeEntities = this.entities.filter(
          item => !item.isIncluded,
        )
      } else {
        this.resource.entities = this.entities.filter(
          item => item.isIncluded,
        )
      }

      this.resource.isCompanyContact = this.selected

      this.errors = await this.yupValidate(
        metastatusSchema({
          $t: v => this.$t(v),
          $n: v => this.$n(v),
          resourceName: this.resourceName,
        }),
        this.resource,
      )
      if (this.errors.length) return

      this.saving = true
      const { error } = await this.$async(
        this.createOrUpdate({
          ...this.resource,
          name: this.resource.metaStatusName,
          rank: this.resource.metaStatusRank,
        }),
      )

      if (error) {
        this.errors = this.$lodash.get(error, 'response.data.errors', [])
      } else {
        this.$emit('saved')
        this.close()
      }
      this.saving = false
    },
    createOrUpdate(data) {
      return this.resourceId
        ? this.service.patch(this.resourceId, data)
        : this.service.store(data)
    },
    onSearchUser(search, loading) {
      this.searchText = search
      if (search.length) {
        loading(true)
        this.searchUser(search, loading, this)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/form-sidebar.scss';

.prozess-metastatus {
  &__entity-default {
    .custom-switch {
      margin-right: 0;
    }
  }
}
.custom-radio.custom-control {
    margin-bottom: 16px;
}
</style>
