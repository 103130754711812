var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":""}},[_c('prozess-metastatus-form',{attrs:{"resource-id":_vm.resourceId,"resource-name":_vm.resourceName,"service":_vm.customService,"visible":_vm.showForm},on:{"close":_vm.onCloseForm,"saved":function($event){return _vm.fetchList()}}}),_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('b-button',{class:_vm.createBtnClass,attrs:{"id":"create-button","variant":"primary"},on:{"click":function($event){_vm.isSystemSettingsPermissions
              ? _vm.hasSystemSettingsActions && _vm.create()
              : _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Create New')))])],1),_c('b-tooltip',{attrs:{"target":"create-button"}},[_vm._v(" "+_vm._s(_vm.toolTipTitle('Create'))+" ")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',[_c('prozess-search-input',{attrs:{"placeholder":_vm.$t('Search'),"value":_vm.search},on:{"input":_vm.handleSearch}})],1)])])],1)],1),_c('div',{staticClass:"m-2"},[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"empty-text":_vm.$t('No matching records found'),"fields":_vm.tableColumns,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"primary-key":"id","responsive":"","show-empty":""},on:{"sort-changed":_vm.handleSortChange},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(metaStatusName)",fn:function({ item }){return [(item.icon)?_c('feather-icon',{staticClass:"mr-1 text-primary",attrs:{"icon":item.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.metaStatusName))])]}},{key:"cell(defaultTo)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.entities .filter((entity) => entity.isDefaultValue) .map(({ tableName }) => _vm.$case.title(tableName)) .join(',')))])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:_vm.createBtnClass,attrs:{"id":`edit-row-${item[_vm.idName]}-preview-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){_vm.isSystemSettingsPermissions
                ? _vm.hasSystemSettingsActions && _vm.edit(item[_vm.idName])
                : _vm.edit(item[_vm.idName])}}}),_c('b-tooltip',{attrs:{"target":`edit-row-${item[_vm.idName]}-preview-icon`,"title":_vm.toolTipTitle('Edit'),"placement":"left"}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:_vm.createBtnClass,attrs:{"id":`delete-row-${item[_vm.idName]}-preview-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){_vm.isSystemSettingsPermissions
                ? _vm.hasSystemSettingsActions && _vm.remove(item)
                : _vm.remove(item)}}}),_c('b-tooltip',{attrs:{"target":`delete-row-${item[_vm.idName]}-preview-icon`,"title":_vm.toolTipTitle('Delete'),"placement":"left"}})],1)]}}])})],1),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }