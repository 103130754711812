<template>
  <b-card no-body>
    <prozess-metastatus-form
      :resource-id="resourceId"
      :resource-name="resourceName"
      :service="customService"
      :visible="showForm"
      @close="onCloseForm"
      @saved="fetchList()"
    />
    <div class="m-2">
      <b-row>
        <b-col
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          cols="12"
          md="6"
        >
          <b-button
            id="create-button"
            :class="createBtnClass"
            variant="primary"
            @click="
              isSystemSettingsPermissions
                ? hasSystemSettingsActions && create()
                : create()
            "
          >
            <feather-icon class="mr-50" icon="PlusIcon" />
            <span class="align-middle">{{ $t('Create New') }}</span>
          </b-button>

          <b-tooltip target="create-button">
            {{ toolTipTitle('Create') }}
          </b-tooltip>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-end">
            <div>
              <prozess-search-input
                :placeholder="$t('Search')"
                :value="search"
                @input="handleSearch"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="m-2">
      <b-table
        ref="refTable"
        :busy="loading"
        :empty-text="$t('No matching records found')"
        :fields="tableColumns"
        :items="items"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        class="table-responsive"
        primary-key="id"
        responsive
        show-empty
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head()="{ label }">
          <span>{{ $t(label) }}</span>
        </template>

        <template #cell(metaStatusName)="{ item }">
          <feather-icon
            v-if="item.icon"
            :icon="item.icon"
            class="mr-1 text-primary"
          />
          <span>{{ item.metaStatusName }}</span>
        </template>

        <template #cell(defaultTo)="{ item }">
          <span>{{
            item.entities
              .filter((entity) => entity.isDefaultValue)
              .map(({ tableName }) => $case.title(tableName))
              .join(',')
          }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="{ item }">
          <div class="text-nowrap">
            <feather-icon
              :id="`edit-row-${item[idName]}-preview-icon`"
              class="mx-1 cursor-pointer"
              :class="createBtnClass"
              icon="EditIcon"
              size="16"
              @click="
                isSystemSettingsPermissions
                  ? hasSystemSettingsActions && edit(item[idName])
                  : edit(item[idName])
              "
            />
            <b-tooltip
              :target="`edit-row-${item[idName]}-preview-icon`"
              :title="toolTipTitle('Edit')"
              placement="left"
            />
            <feather-icon
              :id="`delete-row-${item[idName]}-preview-icon`"
              :class="createBtnClass"
              class="mx-1 cursor-pointer"
              icon="TrashIcon"
              size="16"
              @click="
                isSystemSettingsPermissions
                  ? hasSystemSettingsActions && remove(item)
                  : remove(item)
              "
            />
            <b-tooltip
              :target="`delete-row-${item[idName]}-preview-icon`"
              :title="toolTipTitle('Delete')"
              placement="left"
            />
          </div>
        </template>
      </b-table>
    </div>

    <prozess-pagination
      v-if="!loading"
      class="mx-2 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      @change="handleCurrentPage"
    />
  </b-card>
</template>

<script>
import { swalConfirmDeleteOption } from '@/helpers/app'
import listMixinFactory from '@/mixins/list'
import ProzessMetastatusForm from '@/@core/components/ProzessMetastatusForm.vue'
import MetastatusTypes from '@/constants/metastatusTypes'

export default {
  components: {
    ProzessMetastatusForm,
  },
  mixins: [
    listMixinFactory({
      isCustomService: true,
      isCustomTableColumns: true,
    }),
  ],
  props: {
    resourceName: {
      type: String,
      required: true,
    },
    isSystemSettingsPermissions: {
      type: Boolean,
      default: false,
    },
    customService: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      idName: 'metaStatusId',
      resourceId: null,
      showForm: false,
    }
  },
  computed: {
    routeName() {
      return `${this.resourceName}-settings`
    },

    createBtnClass() {
      if (!this.isSystemSettingsPermissions) return ''
      return {
        'icon-disabled': !this.hasSystemSettingsActions,
      }
    },

    customTableColumns() {
      let columns = [
        {
          label: 'Name',
          key: 'metaStatusName',
          sortable: true,
        },
        {
          label: 'Rank',
          key: 'metaStatusRank',
          sortable: true,
        },
        {
          label: 'Attached To',
          key: 'attachedTo',
          sortable: true,
        },
        {
          label: 'Default on',
          key: 'defaultTo',
          sortable: false,
        },
        {
          key: 'actions',
          class: 'text-center disabled',
        },
      ]

      if (this.resourceName !== MetastatusTypes.PRIORITY) {
        columns = columns.filter(item => item.key !== 'metaStatusRank')
      }

      if (this.resourceName === MetastatusTypes.CATEGORY) {
        columns.splice(3, 0, {
          label: 'Company Contact',
          key: 'isCompanyContact',
          sortable: false,
        })
      }

      return columns
    },
  },
  methods: {
    create() {
      this.resourceId = null
      this.showForm = true
    },

    toolTipTitle(defaultTitle) {
      if (!this.isSystemSettingsPermissions) return this.$t(defaultTitle)
      return this.hasSystemSettingsActions
        ? this.$t(defaultTitle)
        : this.$t('no-action-permission')
    },
    edit(id) {
      this.resourceId = id
      this.showForm = true
    },
    remove({ metaStatusVersion, metaStatusId }) {
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          this.deleteResource(metaStatusId, metaStatusVersion, {
            versionKey: 'metaStatusVersion',
          })
        }
      })
    },
    onCloseForm() {
      this.showForm = false
      setTimeout(() => {
        this.resourceId = null
      }, 500)
    },
  },
}
</script>
