import MetastatusTypes from '@/constants/metastatusTypes'
import * as yup from 'yup'

export const metastatusSchema = ({ $t, resourceName, $n }) => {
  const schema = {
    metaStatusName: yup.string().required().nullable()
      .max(20, `${$t('Max')} 20`),
    entities: yup.array().min(1).required(),
  }

  if (resourceName === MetastatusTypes.PRIORITY) {
    schema.metaStatusRank = yup.number().required().nullable()
      .min(1, `${$t('Min')} 1`)
      .max(1000000, `${$t('Max')} ${$n(1000000)}`)
      .typeError('Required')
  }

  return yup.object().shape(schema)
}
